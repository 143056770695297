 <template>
  <div class="calendar_body">
    <!-- Calendly inline widget begin -->
    <div v-if="isLoading" class="loader">
      One moment getting calendar ready...
    </div>
    <div
      class="calendly-inline-widget"
      style="min-width: 400px; height: 600px"
    ></div>
    <b-alert class="calendar_alert" variant="secondary" show>
      <div
        class="alert-body"
        style="
          display: flex;
          padding: 1rem;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div>
          <p style="font-size: 16px">Do you need a custom license!</p>
          <p>
            If you have a mass production demand and other custom use cases than
            what we offer. We are here to help you.
          </p>
        </div>
        <b-button
          @click="$router.push({ name: 'apps-contact' })"
          variant="primary"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        >
          <span>&nbsp;Contact us</span>
        </b-button>
      </div>
    </b-alert>
  </div>
</template>

<script>
import {
  BCalendar,
  BCardText,
  BCardTitle,
  BAlert,
  BButton,
  BRow,
  BCol,
} from "bootstrap-vue";
import { CircleIcon } from "vue-feather-icons";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCalendar,
    BCardText,
    BCardTitle,
    BAlert,
    BButton,
    BRow,
    BCol,
    CircleIcon,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    value: "",
    context: null,
    isLoading: false,
  }),
  // mounted
  mounted() {
    this.isLoading = true;
    Calendly.initInlineWidget({
      url: "https://calendly.com/hydro-h",
      parentElement: document.querySelector(".calendly-inline-widget"),
      prefill: {},
      utm: {},
    });
    window.addEventListener("message", this.handleCalendy);
  },

  methods: {
    onContext(ctx) {
      this.context = ctx;
    },

    handleCalendy() {
      this.isLoading = false;
      if (e.data.event && e.data.event.indexOf("calendly") === 0) {
        console.log("EVENT", e.data);
      }
    },
  },
};
</script>

<style lang="css">
@import "../../../assets/css/custom.css";

.loader {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
 